import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Click the button below to upload orders</p>
        <a
          className="App-link"
          href="https://8ymol0nw11.execute-api.ap-south-1.amazonaws.com/api/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>UPLOAD</b>
        </a>
        <br/>
        <br/>
        <br/>
        <br/>
        <p>Click the button below to update order statuses</p>
        <a
          className="App-link"
          href="https://huqvx7dth4.execute-api.ap-south-1.amazonaws.com/api/statusupdater"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b>UPDATE STATUS</b>
        </a>
      </header>
    </div>
  );
}

export default App;
